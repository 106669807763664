import united_kingdom from "../assets/images/right-side-menu/united_kingdom.png";
import spain from "../assets/images/right-side-menu/spain.png";
import france from "../assets/images/right-side-menu/france.png";
import italy from "../assets/images/right-side-menu/italy.png";
import germany from "../assets/images/right-side-menu/germany.png";
import excellent from "../assets/images/emojis/excellent.png";
import good from "../assets/images/emojis/good.png";
import average from "../assets/images/emojis/average.png";
import poor from "../assets/images/emojis/poor.png";
import disappointed from "../assets/images/emojis/disappointed.png";
import { communitybaseurl } from "./apiconfig";

const constant = {
  access_token: "access_token",
  refresh_token: "refresh_token",
  expires_in: "expiresIn",
  loggedInId: "loggedInId",
  LOGINCUSTOM: "CUSTOM",
  Exchangerate_API_KEY: "68ed7aff8623fccae955c46f03e7bd02",
  INR_RATE: "INR_RATE",
  EUR_RATE: "EUR_RATE",
  USD_RATE: "USD_RATE",
  GBP_RATE: "GBP_RATE",
  tenantType: "tenantType",
  fontSize: "fontSize",
  language: "language",
  DEFAULT_THEME_COLOR: "#e6efff",
  programSkipped: "programSkipped",
  REDIRECT: "REDIRECT",
  PACKAGE: "PACKAGE",
  ESHOP: "ESHOP",
  RECIPE: "RECIPE",
  COLUMN: "COLUMN",
  CITY: "city",
  TOWN: "town",
  ROW: "ROW",
  CODE_SUCCESS: 200,
  feeds_per_page: 20,
  HOME: "HOME",
  ASSET_IMAGE: "IMAGE",
  ASSET_YOUTUBE: "YOUTUBE",
  ASSET_VIDEO: "VIDEO",
  explore: "EXPLORE",
  chat_room: "CHAT ROOM",
  reels: "REELS",
  quizzes: "QUIZZES",
  contest: "CONTEST",
  games: "GAMES",
  survey: "SURVEY",
  blogs_vlogs: "BLOGS",
  scratch_card: "SCRATCH CARD",
  CARD_COLOR: "COLOR",
  CARD_IMAGE: "IMAGE",
  voucher: "VOUCHER",
  events: "EVENTS",
  refer_friend: "REFER A FRIEND",
  shop_buzz: "SHOPBUZZ",
  polls: "POLLS",
  PRODUCTS: "PRODUCTS",
  HOTTEST_PRODUCT: "Hottest Products",
  hottest_product: "HOTTEST PRODUCT",
  ANNOUNCEMENT: "ANNOUNCEMENT",
  VOUCHERS: "VOUCHER",
  INTERESTS: "Interests",
  INTEREST: "INTEREST",
  WHAT_IS_NEW: "WHAT_IS_NEW",
  POSTS: "POSTS",
  POST: "POST",
  WIDGETS: "WIDGET",
  NEWSFEED: "NEWSFEED",
  VIDEO: "VIDEO",
  IMAGE: "IMAGE",
  DOCUMENT: "DOCUMENT",
  IMAGE_GIF: "IMAGE_GIF",
  image_gif: "image/gif",
  TEXT: "TEXT",
  POSTTYPE_POST: "POST",
  POSTTYPE_REELS: "REELS",
  POSTTYPE_ANNOUNCEMENT: "ANNOUNCEMENT",
  POSTTYPE_BLOG: "BLOG",
  POSTTYPE_VLOG: "VLOG",
  POSTTYPE_CONTEST: "CONTEST",
  POSTTYPE_QUIZ: "QUIZ",
  POSTTYPE_SURVEY: "SURVEY",
  POSTTYPE_EVENTS: "EVENT",
  POSTTYPE_POLL: "POLL",
  POSTTYPE_SCRATCH_CARD: "SCRATCH_CARD",
  POST_SHARED_IN_COMMUNITY: "POST_SHARED_IN_COMMUNITY",
  POST_SHARED: "POST_SHARED",
  POST_ADDED: "POST_ADDED",
  POST_LIKED: "POST_LIKED",
  POST_COMMENT_ADDED: "POST_COMMENT_ADDED",
  LanguageList: [
    {
      id: "en",
      name: "English",
      img: united_kingdom,
    },
    {
      id: "it",
      name: "Italy",
      img: italy,
    },
    {
      id: "es",
      name: "Spain",
      img: spain,
    },
    {
      id: "de",
      name: "Germany",
      img: germany,
    },
    {
      id: "fr",
      name: "France",
      img: france,
    },
  ],

  reactions: [
    { label: "up", node: <div>👍</div>, key: "THUMB_UP" },
    { label: "haha", node: <div>😄</div>, key: "SMILE" },
    { label: "heart", node: <div>❤️</div>, key: "HEART" },
    { label: "LoL", node: <div>😮</div>, key: "LOL" },
    { label: "sad", node: <div>😥</div>, key: "SAD" },
    { label: "down", node: <div>👎</div>, key: "THUMB_DOWN" },
  ],
  Facebook: "Facebook",
  Twitter: "Twitter",
  LinkedIn: "LinkedIn",
  Reddit: "Reddit",
  WhatsApp: "WhatsApp",
  Pinterest: "Pinterest",
  logo: "logo",
  privacy_policy: "privacy_policy",
  company_name: "company_name",
  join: "Join",
  influencer: "Influencer",
  network: "Network",
  my_scratch: "My Scratch Cards",
  event: "Events",
  my_post: "My Posts",
  my_profile: "My Profile",
  settings: "Settings",
  followers: "Followers",
  booking_history: "Booking History",
  purchase_history: "Purchase History",
  influencer_target: "Influencer Target",
  terms_policies: "Terms & Policies",
  tier: "Tier",
  badges: "Badges",
  todo_list: "Todo List",
  tier1: "TIER_1",
  tier2: "TIER_2",
  tier3: "TIER_3",
  tier4: "TIER_4",
  DB_DATE_YYYYMMDD: "YYYY-MM-DD",
  reportoptions: [
    { id: 1, name: "Inappropriate" },
    { id: 2, name: "Rude" },
    { id: 3, name: "Spam" },
    { id: 4, name: "Other" },
  ],
  currencyList: ["INR", "EUR", "USD", "GBP"],
  rememberEmail: "rememberEmail",
  rememberPassword: "rememberPassword",
  MAX_POLLASSETS: 8,
  SMS: "SMS",
  Push: "Push",
  Email: "Email",
  Chat: "Chat",
  no_refresh_token: "no_refresh_token",
  FACEBOOK: "FACEBOOK",
  LINKED_IN: "LINKED_IN",
  TWITTER: "TWITTER",
  REDDIT: "REDDIT",
  WHATSAPP: "WHATSAPP",
  INSTAGRAM: "INSTAGRAM",
  PINTEREST: "PINTEREST",
  INTRO: "INTRO",
  postSteps: [
    {
      question: "How to make a post",
      url: "/",
      element: ".msgbox",
      intro: "Click here to create a new post",
      initialStep: 0,
    },
    {
      question: "How to share a post",
      url: "/",
      element: ".share_intro_0",
      intro: "Click here to share on social media",
      initialStep: 1,
    },
  ],

  reelsSteps: [
    {
      question: "How to upload reels",
      url: "/reels",
      element: ".intro-reels",
      intro: "Click here to upload your reels",
      initialStep: 0,
    },
  ],

  quizSteps: [
    {
      element: ".intro-quiz",
      question: "How to play a Quiz",
      url: "/quiz",
      intro: "Click here to play a quiz",
      initialStep: 0,
    },
  ],

  surveyStps: [
    {
      question: "How to start a Survey",
      url: "/survey",
      element: ".intro-survey",
      intro: "Click here to start a survey",
      initialStep: 0,
    },
  ],
  pollSteps: [
    {
      question: "How to create a Poll",
      url: "/polls",
      element: ".create-poll-button",
      intro: "Click here to create a Poll",
      initialStep: 0,
    },
  ],
  contestSteps: [
    {
      question: "How to participate in a Contest",
      url: "/contestlist",
      element: ".intro-participate",
      intro: "Click here to participate a Contest",
      initialStep: 0,
    },
  ],
  scratchcardSteps: [
    {
      question: "How to create a Scratchcard",
      url: "/scratch",
      element: ".create-new",
      intro: "Click here to create a Scratchcard",
      initialStep: 0,
    },
  ],
  eventSteps: [
    {
      question: "How to register an Event",
      url: "/events",
      element: ".intro-register",
      intro: "Click here to register an Event",
      initialStep: 0,
    },
  ],
  pointSteps: [
    {
      question: "How to check your points",
      url: "/wallet",
      element: ".intro-points",
      intro: "You can check your points here",
      initialStep: 0,
    },
  ],
  profileSteps: [
    {
      question: "How to follow/add a friends",
      url: "/profile",
      element: ".network-tab",
      intro: "Follow/Add friends here",
      initialStep: 0,
    },
    {
      question: "How to hit my monthly targets",
      url: "/profile",
      initialStep: 1,
      element: ".influencer-target",
      intro: "Check your monthly targets here",
    },
  ],
  AWAITING_PAYMENT_REFERENCE: "AWAITING_PAYMENT_REFERENCE",
  Payment: {
    AWAITING_PAYMENT_REFERENCE: "Waiting",
  },
  PICTURE_BACKGROUND: "PICTURE_BACKGROUND",
  PICTURE_IN_MIDDLE: "PICTURE_IN_MIDDLE",
  PICTURE_LEFT: "PICTURE_LEFT",
  connect_your_social_account: "Connect your social account",
  import_content_to_Cohora: "Import content to Cohora",
  content_library: "Content library",
  scheduled_activity: "Scheduled activity",
  RedirectUri: `${communitybaseurl}social/import-content`,
  scheduletype: ["Daily", "Weekly", "Monthly", "Once", "Ongoing"],
  unsubscribelist: [
    "Your emails are not relevant to me",
    "Your emails are too frequent",
    "I no longer want to receive these emails",
    "Other",
  ],
  my_task: "My Tasks",
  influencer_tasks: "Influencer tasks",
  Badges: "Badges",
  my_activity: "My Activity",
  wrong_old_password: "wrong_old_password",
  wrong_old_password_helptext: "Your old password was entered incorrectly",
  emojis: [
    {
      name: "Disappointed",
      selected: false,
      emoji_name: "&#128529;",
      image: disappointed,
    },
    {
      name: "Average",
      selected: false,
      emoji_name: "&#128578;",
      image: poor,
    },
    {
      name: "Neutral",
      selected: false,
      emoji_name: "&#128522;",
      image: average,
    },
    {
      name: "Good",
      selected: false,
      emoji_name: "&#128512;",
      image: good,
    },
    {
      name: "Excellent",
      selected: false,
      emoji_name: "&#128515;",
      image: excellent,
    },
  ],
  Apparels: "Apparels",
  whooz: "whooz",
  addwest: "addwest",
  golfavenue: "golfavenue",
  readMoreLines: 2,
  primaryColor: "primaryColor",
  interactiveColor: "interactiveColor",
  secondaryColor: "secondaryColor",
  neutralColor: "neutralColor",
};

export const IMAGE_TYPES = "image/gif, image/jpeg, image/png, image/bmp, ";
export const GIF_TYPES = "image/gif, ";
export const VIDEO_TYPES = "video/mp4, video/avi, video/webm, ";
export const IMAGE_TYPES_PROFILE = "image/jpeg, image/png";

export default constant;

export const transactionTypes = {
  BRANDED_POST_COMMENT_ADDED: "Brand post comments added to the post",
  COMMENTED_SOMETHING: "Post comments added to the post",
  POST_COMMENT_ADDED: "Post comments added to the post",
  INFLUENCER_TARGET_REACHED: "Reached your influencer target level",
  BLOG_LIKED: "Blog post liked",
  POST_WITH_IMAGE_SHARED: "Image post shared",
  POST_SHARED_IN_COMMUNITY: "Post shared within the community",
  BRAND_POST_LIKED: "Post liked loyalty points",
  QUIZ_LIKED: "Quiz liked loyalty points",
  POST_WITH_VIDEO_SHARED: "Video post shared",
  POST_SHARED: "Post shared",
  BLOG_POST_ADDED: "Added new blog post",
  POST_LIKED: "Post liked",
  FRIEND_ADDED: "Added a new friend",
  FRIEND_REMOVED: "Removed a friend",
  ENTRY_ADDED: "Added a new Entry",
  POST_A_POLL: "Posted a new Poll",
  SURVEY_STARTED: "Started a survey",
  QUIZ_ANSWER_SUBMITTED_CORRECT: "Submitted a quiz with correct answers",
  QUIZ_ANSWER_SUBMITTED_WRONG: "Submitted a quiz with wrong answers",
  CHAT_P2P_MESSAGE_RECEIVED: "Received a chat message",
  ANNOUNCEMENT_LIKED: "Liked an Announcement",
  PRODUCT_REVIEW_APPROVED: "Approved your product review",
  PRODUCT_RATED: "Product rated",
  DATE_OF_BIRTH_SET: "Added your date of birth",
  SURVEY_COMPLETED: "Survey completed",
  COMMENT_LIKED: "Post comments liked",
  BRAND_COMMENT_LIKED: "Brand post comments liked",
  POLL_LIKED: "Poll liked",
  POST_VIDEO: "Added a new video post",
  POST_IMAGE: "Added a new image post",
  REELS_LIKED: "Liked a Reels",
  CHAT_ROOM_JOINED: "Joined a chat room",
  PROFILE_TAGGED_IN_POST: "Post tagged with a profile",
  EVENT_POST_ADDED: "Added a new Event post",
  POST_ADDED: "Added a new post",
  BRAND_POST_ADDED: "Added a new brand post",
  SURVEY_ANSWER_SUBMITTED: "Submitted a survey",
  QUIZ_COMPLETED: "Quiz completed",
  QUIZ_STARTED: "Quiz started",
  PRODUCT_REVIEWED: "Your product has been reviewed",
  CHAT_ROOM_MESSAGE_RECEIVED: "Chat message received successfully",
  VOUCHER_PURCHASED: "Coupon purchased successfully",
  CONTEST_LIKED: "Contest liked successfully",
  CHAT_P2P_MESSAGE_POSTED: "Chatted successfully",
  CHAT_ROOM_MESSAGE_POSTED: "Chatted with chat room",
  TAG_ADDED: "Added a Tag",
  INFLUENCER_JOIN: "Influencer joined",
  PRODUCT_SHARED: "Product shared",
  SURVEY_LIKED: "Survey liked",
  NONBRAND_POST_LIKED: "Post liked",
  VIDEO_POST_LIKED: "Video liked",
  IMAGE_POST_LIKED: "Image liked",
  ENTRY_LIKED: "Entry liked",
  NONBRAND_COMMENT_LIKED: "Comment liked",
  VLOGS_LIKED: "Vlogs liked",
};

export const multiplierBonusText = (
  multiplier = 0
) => `The Tier Bonus coefficient for each level entails multiplying
each earned point by a factor of
${multiplier}. For instance, if you
publish a brand post and unlock 30 points, your total earned
points would be (30 x ${multiplier} =
${multiplier * 30} points)`;

export const pointsToUpgradeText = (points = 0) =>
  `Total points required to advanve to the next tier is ${points}`;

export const generalInfluencerRulesText = [
  "You advance to higher levels by attaining 100% of the required points. However, failing to achieve at least 30% of the potential points available for total tasks will result in downgrading to the Bronze level or remaining at that level.",
  "Points accrued can be exchanged for vouchers, complimentary gifts, product discounts, and more.",
];

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

export const InstagramHome = "https://www.instagram.com/";
export const SnapchatHome = "https://www.snapchat.com/";
export const TiktokHome = "https://www.tiktok.com/";
export const ThreadsHome = "https://www.threads.net/";
